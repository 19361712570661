import React from 'react';
import cn from 'classnames';
import { LogoColor } from '../../Assets';
import AppConfigContext from '../AppConfig/AppConfigContext';
import { MyImage } from '../my-image/MyImage';
import { image } from './logo-app.css';

type LogoAppProps = {
	className?: string;
};

const LogoApp = (props: LogoAppProps) => {
	const { manifest } = React.useContext(AppConfigContext);

	const classnames = cn('main-logo', props.className, image);

	if (!manifest.branding?.logo) {
		return <LogoColor className={classnames} />;
	}

	return (
		<MyImage
			src={manifest.branding.logo.value}
			alt={`Logo ${manifest.name}`}
			className={classnames}
		/>
	);
};

export default LogoApp;
