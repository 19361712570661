import cn from 'classnames';
import {
	image,
	container,
	ratio251x200,
	ratioAuto,
	background,
	ratio800x600,
	absolute,
} from './my-image.css';

type ImageProps = {
	src?: string;
	alt: string;
	styles?: object;
	className?: string;
	lazy?: boolean;
	ratio?: '800x600' | '251x200' | 'auto';
};

export const MyImage = (props: ImageProps) => {
	const _lazy = props.lazy ?? true;
	const _ratio = props.ratio ?? 'auto';

	if (!props.src) {
		return null;
	}

	return (
		<div
			className={cn(container, props.className, {
				[ratio800x600]: _ratio === '800x600',
				[ratio251x200]: _ratio === '251x200',
				[ratioAuto]: _ratio === 'auto',
			})}
		>
			{_ratio === 'auto' ? null : <div className={background} />}
			<img
				loading={_lazy ? 'lazy' : undefined}
				decoding={_lazy ? 'async' : undefined}
				role="presentation"
				src={props.src}
				alt={props.alt}
				className={cn('image', props.className, image, {
					[absolute]: _ratio !== 'auto',
				})}
				style={props.styles}
			/>
		</div>
	);
};
